/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from "@tanstack/react-router";

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as GameJoinAiImport } from "./routes/game/join/ai";
import { Route as GameJoinGameIDImport } from "./routes/game/join/$gameID";

// Create Virtual Routes

const ProfileLazyImport = createFileRoute("/profile")();
const IndexLazyImport = createFileRoute("/")();

// Create/Update Routes

const ProfileLazyRoute = ProfileLazyImport.update({
  id: "/profile",
  path: "/profile",
  getParentRoute: () => rootRoute,
} as any).lazy(() => import("./routes/profile.lazy").then((d) => d.Route));

const IndexLazyRoute = IndexLazyImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any).lazy(() => import("./routes/index.lazy").then((d) => d.Route));

const GameJoinAiRoute = GameJoinAiImport.update({
  id: "/game/join/ai",
  path: "/game/join/ai",
  getParentRoute: () => rootRoute,
} as any);

const GameJoinGameIDRoute = GameJoinGameIDImport.update({
  id: "/game/join/$gameID",
  path: "/game/join/$gameID",
  getParentRoute: () => rootRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/";
      path: "/";
      fullPath: "/";
      preLoaderRoute: typeof IndexLazyImport;
      parentRoute: typeof rootRoute;
    };
    "/profile": {
      id: "/profile";
      path: "/profile";
      fullPath: "/profile";
      preLoaderRoute: typeof ProfileLazyImport;
      parentRoute: typeof rootRoute;
    };
    "/game/join/$gameID": {
      id: "/game/join/$gameID";
      path: "/game/join/$gameID";
      fullPath: "/game/join/$gameID";
      preLoaderRoute: typeof GameJoinGameIDImport;
      parentRoute: typeof rootRoute;
    };
    "/game/join/ai": {
      id: "/game/join/ai";
      path: "/game/join/ai";
      fullPath: "/game/join/ai";
      preLoaderRoute: typeof GameJoinAiImport;
      parentRoute: typeof rootRoute;
    };
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  "/": typeof IndexLazyRoute;
  "/profile": typeof ProfileLazyRoute;
  "/game/join/$gameID": typeof GameJoinGameIDRoute;
  "/game/join/ai": typeof GameJoinAiRoute;
}

export interface FileRoutesByTo {
  "/": typeof IndexLazyRoute;
  "/profile": typeof ProfileLazyRoute;
  "/game/join/$gameID": typeof GameJoinGameIDRoute;
  "/game/join/ai": typeof GameJoinAiRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  "/": typeof IndexLazyRoute;
  "/profile": typeof ProfileLazyRoute;
  "/game/join/$gameID": typeof GameJoinGameIDRoute;
  "/game/join/ai": typeof GameJoinAiRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths: "/" | "/profile" | "/game/join/$gameID" | "/game/join/ai";
  fileRoutesByTo: FileRoutesByTo;
  to: "/" | "/profile" | "/game/join/$gameID" | "/game/join/ai";
  id: "__root__" | "/" | "/profile" | "/game/join/$gameID" | "/game/join/ai";
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute;
  ProfileLazyRoute: typeof ProfileLazyRoute;
  GameJoinGameIDRoute: typeof GameJoinGameIDRoute;
  GameJoinAiRoute: typeof GameJoinAiRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  ProfileLazyRoute: ProfileLazyRoute,
  GameJoinGameIDRoute: GameJoinGameIDRoute,
  GameJoinAiRoute: GameJoinAiRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/profile",
        "/game/join/$gameID",
        "/game/join/ai"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/profile": {
      "filePath": "profile.lazy.tsx"
    },
    "/game/join/$gameID": {
      "filePath": "game/join/$gameID.tsx"
    },
    "/game/join/ai": {
      "filePath": "game/join/ai.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
